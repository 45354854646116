// import { popup } from '../../components/Popup/Popup';
import { queryClient } from '../../layouts/ReactQueryLayout';
import { profile_local } from '../LocalStorage';
import { Axios } from './axios';

const getProfile = () => {
  // return {
  //   _id: '663d2d628a48dd52f0f4a745',
  //   first_name: 'youssef',
  //   last_name: 'rouas',
  //   email: 'rouasyoussef1@gmail.com',
  //   password: '$2b$12$ByQHsuLzrNGeDRPf7MUbUuPnZ9foFNgK1Tbxakn2lXD1zvaC7Grde',
  //   role: 'Admin',
  //   active: false,
  //   otpVerified: true,
  //   history: [],
  //   createdAt: '2024-05-09T20:09:06.916Z',
  //   updatedAt: '2024-05-10T11:05:59.485Z',
  //   __v: 0,
  //   otp: '16649',
  // };

  if( ! localStorage.getItem("token")) return null

  return Axios.get(`/profile/`).then(({ data }) => {
        const { email, _id, role } = data;
        if (data?._id)
          localStorage.setItem('user', JSON.stringify({ email, _id, role }));
        else {
          localStorage.removeItem('user');
          localStorage.removeItem('token');
          queryClient.invalidateQueries('token');
        }
        console.log({ Profile: data });
        return data;
      }).catch(err=>{
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        queryClient.invalidateQueries('token');
   
      })
};

const updateProfile = ({ data }) => {
  let id = JSON.parse(localStorage.getItem('user') || '{}')?._id;
  return id
    ? Axios.put(`/profile/`, data).then(({ data }) => {
        console.log({ Profile: data });
        return data;
      })
    : {};
};

const getUser = ({ id }) => {
  return Axios.get(`/get-user/${id}`).then(({ data }) => {
    console.log({ User: data?.user });
    return data?.user;
  });
};

const getUsers = () => {
  return Axios.get(`/users`).then(({ data }) => {
    console.log({ Users: data });

    return data;
  });
};

const getOwner = () => {
  return Axios.get(`/`).then(({ data }) => {
    console.log({ Owner: data?.owner });
    return data?.owner;
  });
};

const getOwners = () => {
  return Axios.get(`/owners`).then(({ data }) => {
    console.log({ Owners: data?.owners });
    return data?.owners;
  });
};
const getRenters = () => {
  return Axios.get(`/renters`).then(({ data }) => {
    console.log({ Renters: data?.renters });
    return data?.renters?.map((r) => ({
      ...r.user,
      rentedProperties: r?.rentedProperties,
    }));
  });
};

const createUser = ({ data }) => {
  console.log(data);
  return Axios.post(`/create-user`, data).then(({ data }) => {
    queryClient.invalidateQueries('users');
    // popup.show({ title: 'Ajouter', type: 'notification' });
    return data;
  });
};

const updateUser = ({ id, data }) => {
  return Axios.put(`/update-user/${id}`, data).then(({ data }) => {
    queryClient.invalidateQueries('users');
    // popup.show({ title: 'Modifier', type: 'notification' });
    return data;
  });
};

const deleteUser = ({ id }) => {
  return Axios.delete(`/delete-user/${id}`)
    .then(({ data }) => {
      queryClient.invalidateQueries('users');
      // popup.show({ title: 'Supprimé', type: 'notification' });
      return data;
    })
    .catch((err) => {
      console.log(err);
    });
};

const getTechniciens = () => {
  return Axios.get(`/techniciens`).then(({ data }) => {
    console.log({ Techniciens: data });
    return data;
  });
};

const deleteNotification = (id) => {
  return Axios.delete(`/delete-notification/${id}`).then(({ data }) => {
    queryClient.invalidateQueries('profile');
  });
};

const confirmNotification = (id, data) => {
  return Axios.put(`/confirm-payment/${id}`, data).then(({ data }) => {
    queryClient.invalidateQueries('profile');
  });
};

export {
  getProfile,
  getUser,
  getOwners,
  getUsers,
  deleteUser,
  createUser,
  updateUser,
  updateProfile,
  getOwner,
  getTechniciens,
  getRenters,
  deleteNotification,
  confirmNotification,
};
