import React from 'react';
import Icon from '../Icon/Icon';
import { createCapture } from '../../utils/api/capture';
import moment from 'moment';
import 'moment/locale/fr'; // without this line it didn't work
import { useNavigate } from 'react-router-dom';
moment.locale('fr');

function Vérifier({ Go_To, getValues, profile, watch,Onsubmit }) {
  const router = useNavigate();
  return (
    <div className='item-container'>
      <div className='container'>
        <h4>Veuillez confirmer les détails de votre réservation</h4>
        <p>
          Notre technicien de capture appellera cette personne à son arrivée.
        </p>
        <div
          className='right'
          style={{
            background: '#F3F4F8',
            borderRadius: 10,
            paddingTop: 10,
            marginTop: 10,
          }}
        >
          <h5 className='bold' style={{ marginTop: 0 }}>
            Détails de la propriété
          </h5>
          <p className='bold'>Adresse</p>
          <div
            className='flex '
            style={{
              justifyContent: 'space-between',
              flexWrap: 'wrap',
              gap: 10,
            }}
          >
            {watch('address')} {watch('ville')} {watch('pays')}
            <span onClick={() => Go_To(0)}>Mettre à jour</span>
          </div>

          <br />
          <p>
            <span className='bold'>Détails de la numérisation:</span>
            <br />
            <div
              className='flex'
              style={{
                justifyContent: 'space-between',
                flexWrap: 'wrap',
                gap: 10,
              }}
            >
              <span>
                <span className='bold'>
                  {' '}
                  Type de propriété: {watch('numerisation')}{' '}
                </span>{' '}
                <br />
                Détails de la propriété: {watch('numerisation')}
              </span>
              <span onClick={() => Go_To(0)}>Mettre à jour</span>
            </div>
          </p>
          <br />
          <div className='bold'>Détails de la propriété</div>
          <div>1 espace totalisant {watch('superficie')} m²</div>
          <p
            className='flex '
            style={{
              justifyContent: 'space-between',
              flexWrap: 'wrap',
              gap: 10,
            }}
          >
            <div className='bold'> Nom de l'espace à numériser:</div>{' '}
            <span onClick={() => Go_To(0)}>Mettre à jour</span>
          </p>
          <span>{watch('ville')}</span>
          <p
            className='flex bold'
            style={{
              justifyContent: 'space-between',
              flexWrap: 'wrap',
              gap: 10,
            }}
          >
            Capture services <span>{Number(watch('superficie')) * 25} MAD</span>
          </p>
        </div>
        <div
          className='right'
          style={{
            background: '#F3F4F8',
            borderRadius: 10,
            paddingTop: 10,
            marginTop: 10,
          }}
        >
          <h5 className='bold' style={{ marginTop: 0 }}>
            Rendez vous
          </h5>

          <div
            className='flex '
            style={{
              justifyContent: 'space-between',
              flexWrap: 'wrap',
              gap: 10,
            }}
          >
            Le {moment(watch('date')).format('dddd DD MMMM ')} à {watch('time')}{' '}
            (GMT+)
            <span onClick={() => Go_To(1)}>Mettre à jour</span>
          </div>
        </div>
        <div
          className='right'
          style={{
            background: '#F3F4F8',
            borderRadius: 10,
            paddingTop: 10,
            marginTop: 10,
          }}
        >
          <h5 className='bold' style={{ marginTop: 0 }}>
            Contact
          </h5>
          <p>
            {watch('name')} <br />
            {watch('email')} <br />
            {watch('phone')}
            <br />
          </p>
          <p
            className='flex  '
            style={{
              justifyContent: 'space-between',
              flexWrap: 'wrap',
              gap: 10,
            }}
          >
            <span className='bold'>
              Le {moment(watch('date')).format('dddd DD MMMM ')} à{' '}
              {watch('time')} (GMT+)
            </span>
            <span onClick={() => Go_To(2)}>Mettre à jour</span>
          </p>
        </div>
      </div>
      <div className='right'>
        <h3 className='bold'>Montant Total à Payer </h3>
        <h2 className='bold'>{Number(watch('superficie')) * 25} MAD</h2>
        <div className='line'></div>
        <p className='paragraph'>
          Informations Importantes Relatives à Votre Commande :
          <ul>
            <li>
              Responsabilité : Vous êtes tenu(e) pour responsable de
              l'exactitude des informations que vous avez fournies. En cas
              d'informations erronées, des frais additionnels peuvent vous être
              imputés.
            </li>
            <li>
              Préparation de l'Espace : Il est de votre ressort de préparer
              adéquatement l'espace avant l'arrivée de notre technicien. Si
              l'espace n'est pas prêt au moment convenu, cela pourrait engendrer
              des coûts supplémentaires.
            </li>
            <li>
              Annulation : Vous avez la possibilité d'annuler votre rendez-vous
              sans pénalité jusqu'à 24 heures avant l'heure prévue. Si vous
              souhaitez annuler après ce délai, veuillez consulter notre
              politique d'annulation complète.
            </li>
            <li>
              Reprogrammation : Vous pouvez reprogrammer votre rendez-vous
              jusqu'à 24 heures avant l'heure initialement prévue. Pour cela,
              veuillez suivre les instructions fournies dans notre politique de
              reprogrammation.
            </li>
            <li>
              Personnalisation : Le nom de l'espace indiqué dans votre commande
              correspondra à l'adresse que vous avez fournie. Vous aurez la
              possibilité de le modifier jusqu'à la finalisation de la commande.
            </li>
          </ul>{' '}
        </p>
        <button
          onClick={() => {
            Onsubmit();
          }}
          style={{ marginTop: 20 }}
          className='add'
        >
            Suivant
        </button>
      </div>
    </div>
  );
}

export default Vérifier;
