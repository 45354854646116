import React from 'react';
import './PackComponent.css';
import { useNavigate } from 'react-router';

const PackComponent = () => {
  const router = useNavigate();

  return (
    <div className='w-[98%]  flex bg-black rounded-[32px] py-[10px] px-[11px] gap-3 PackComponent'>
      <div className='w-[80%] flex p-6 bg-white rounded-[28px] gap-4'>
        <div className='flex-1 text-left left-list'>
          <h2 className='text-lg font-bold mb-4'>Ce pack vous comprends :</h2>
          <ul className='grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2 '>
            <li>Pack Photos HDR</li>
            <li>Visite Virtuelle</li>
            <li>Cloud 10GB d'espace</li>
            <li>Publication (Google Street View)</li>
            <li>Mattertags illimités</li>
            <li>Mise à jour annuelle</li>
            <li>Statistiques d'engagements</li>
            <li>Intégration de Menu Interactif</li>
            <li>Vidéo de Restaurant</li>
            {/* <li>Website Immersive</li> */}
          </ul>
        </div>
        <div className='shrp-pack  flex-1 text-white flex flex-col items-center justify-center '>
          <div className='item'>
            <div className='top'>
              <div>à seulement</div>
              <div></div>
            </div>
            <div className='body'>
              <p className='font-extrabold '>
                1950 <span>MAD</span>
              </p>
              <img src='/images/maps.png' alt='' srcset='' />
            </div>
          </div>
        </div>
      </div>
      <button
        className=' flex items-center justify-center  font-medium text-[30px] 
      rounded-[28px] bg-[#1576FE] text-white cursor-pointer'
        onClick={() => router('/contact')}
      >
        Choisir le Pack
      </button>
    </div>
  );
};

export default PackComponent;
