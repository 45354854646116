import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import Icon from '../Icon/Icon';
import { registerUser } from '../../utils/api/auth';

const Register = () => {
  const [ShowPass, setShowPass] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();
 
  const password = watch('password');

  return (
    <div className='p-10'>
      <div className='mb-20'>
        <img src='/images/authLogo.svg' alt='' className='pl-5' />
      </div>
      <div className='flex items-stretch justify-center w-[85%] mx-auto '>
        <div
          className='w-[40%] md:w-full px-8 '
          style={{ minWidth: 300, maxWidth: 500 }}
        >
          <h2 className='text-[4rem] font-bold mb-4'>Créer un compte</h2>
          <p className='mb-6 text-[1.8rem] text-[#969696] font-normal'>
            Veuillez vous connecter pour continuer à accéder à votre compte.
          </p>

          <form
            onSubmit={handleSubmit(registerUser)}
            className='w-[90%] inputs-label'
          >
            <span className='text-[1.7rem] text-[#6C6C6C] font-normal'>
              Vous avez déjà un compte?{' '}
              <Link
                to={'/login'}
                className='text-[#367AFF] font-semibold underline cursor-pointer'
              >
                Se connecter
              </Link>
            </span>
            <div className='mb-4 '>
              <div className='input-container'>
                <input
                  type='text'
                  required
                  {...register('name', { required: 'Nom est requis' })}
                  className='w-full p-3 border rounded-lg'
                />
                <label className='block mb-2'>Votre nom</label>
              </div>

              {errors.name && (
                <span className='text-red-500'>{errors.name.message}</span>
              )}
            </div>
            <div className='mb-4'>
              <div className='input-container'>
                <input
                required
                  type='text'
                  {...register('email', { required: 'Email est requis' })}
                  className='w-full p-3 border rounded-lg'
                />
                <label className='block mb-2'>Votre Email</label>
              </div>
              {errors.email && (
                <span className='text-red-500'>{errors.email.message}</span>
              )}
            </div>
            <div className='mb-4'>
              <div className='input-container'>
                <input
                  type='email'
                  {...register('confirmEmail', {
                    required: "Confirmation de l'email est requise",
                    validate: (value) =>
                      value === watch('email') ||
                    'Les emails ne correspondent pas',
                  })}
                  required
                  className='w-full p-3 border rounded-lg'
                />
                  <label className='block mb-2'>Confirmer votre Email</label>
              </div>
              {errors.confirmEmail && (
                <span className='text-red-500'>
                  {errors.confirmEmail.message}
                </span>
              )}
            </div>
            <div className='mb-4'>
              <div className='mt-4 input-container'>
                <input
                  type={ShowPass ? 'text' : 'password'}
                  {...register('password', {
                    required: 'Mot de passe est requis',
                  })}
                  className='w-full p-3 border rounded-lg'
               required
               autoComplete='new-password'
               />
                  <label className='block mb-2'>Mot de passe</label>
                <button
                  type='button'
                  className='eye'
                  onClick={() => setShowPass((v) => !v)}
                >
                  <Icon name={ShowPass ? 'hide-eye' : 'eye'} />
                </button>
              </div>
              {errors.password && (
                <span className='text-red-500'>{errors.password.message}</span>
              )}
            </div>
            {/* <div className='mb-4'>
              <div className='mt-4 input-container'>
                <input
                  type={ShowPass ? 'text' : 'password'}
                  {...register('confirm_password', {
                    required: 'Confirmer le mot de passe est requis',
                  })}
                  className='w-full p-3 border rounded-lg'
               required
               autoComplete='new-password'
               />
                  <label className='block mb-2'>Confirmer le mot de passe</label>
                <button
                  type='button'
                  className='eye'
                  onClick={() => setShowPass((v) => !v)}
                >
                  <Icon name={ShowPass ? 'hide-eye' : 'eye'} />
                </button>
              </div>
              {errors.password && (
                <span className='text-red-500'>{errors.password.message}</span>
              )}
            </div> */}
            <button
              type='submit'
              className='w-full bg-[#367AFF] text-white text-[1.8rem] font-semibold py-[1rem] px-[8px] rounded-[1rem] mb-4 content-center h-[5.4rem] '
            >
              Créer un compte
            </button>
            <div className='flex items-center text-[#6E6E6E] gap-2 px-2 font-medium  mb-4'>
              <div className='bg-[#D9D9D9] h-[2px] flex-1'></div>
              <span className='or'>Où</span>
              <div className='bg-[#D9D9D9] h-[2px] flex-1'></div>
            </div>
            <button
              type='button'
              className='w-full  bg-white text-[1.8rem] font-semibold py-[1rem] px-[0.8rem] rounded-[10px] mb-8 flex items-center justify-center gap-2 h-[5.4rem] '
            >
              Continuer avec Google
              <img src='/google.svg' alt='' />
            </button>
          </form>
        </div>
        <div className='w-[55%] hidden md:flex '>
          <img
            src='/images/authImage.svg'
            style={{
              height: '100%',
              objectFit: 'contain',
              objectPosition: 'center',
            }}
            alt=''
          />
        </div>
      </div>
    </div>
  );
};

export default Register;
