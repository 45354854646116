import { useEffect, useState } from 'react';
import './Header.css';
import { NavLink, useNavigate, useParams } from 'react-router-dom';

function Header() {
  const menu_click = () => {
    document.querySelector('.menu').classList.toggle('active');
    document.querySelector('.menu-button').classList.toggle('active');
  };

  const { id } = useParams();
  const [showHeader, setShowHeader] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  const handleScroll = () => {
    const currentScrollY = window.scrollY;
    if (currentScrollY > lastScrollY) {
      setShowHeader(false);
      try {
        document.querySelector('.menu').classList.remove('active');
        document.querySelector('.menu-button').classList.remove('active');
      } catch (error) {}
    } else {
      setShowHeader(true);
    }
    setLastScrollY(currentScrollY);
  };

  // useEffect(() => {
  //   window.addEventListener('scroll', handleScroll);

  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, [lastScrollY]);

  useEffect(() => {
    try {
      document.querySelector('.menu').classList.remove('active');
      document.querySelector('.menu-button').classList.remove('active');

      const y =
        document.querySelector(`#${id || 'hero'}`).getBoundingClientRect().top +
        window.scrollY -
        140;
      window.scroll({
        top: y,
        behavior: 'smooth',
      });
    } catch (error) {}
  }, [id]);

  return (
    <div className={`header-container  ${showHeader ? 'active' : ''}`}>
      <div className='container'>
        <header className='Header'>
          <img src={'/images/logo.svg?v=1'} className='App-logo' alt='logo' />
          <menu className='menu'>
            <ul>
              <li>
                <NavLink to='/'>Accueil</NavLink>
              </li>
              <li>
                <NavLink to='/a-propos'>À propos</NavLink>
              </li>
              <li>
                <NavLink to='/services'>Services</NavLink>
              </li>
              <li>
                <NavLink to='/contact'>Contact</NavLink>
              </li>

              <button>
                <NavLink to='/login'>Se connecter </NavLink>
              </button>
            </ul>
          </menu>
          <button className='menu-button' onClick={menu_click}>
            <div className='bar'></div>
            <div className='bar'></div>
            <div className='bar'></div>
          </button>
          <div className='overlay' onClick={menu_click}></div>
        </header>
      </div>
    </div>
  );
}

export default Header;
