import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
  Routes,
} from 'react-router-dom';
import './App.css';
import Header from './components/Header/Header';
import Hero from './components/Hero/Hero';
import Map from './components/Map/Map';
import Services from './components/Services/Services';
import StepsCapt from './components/StepsCapt/StepsCapt';
import Food from './Food/Food';
import Footer from './components/Footer/Footer';
import FAQ from './components/FAQ/FAQ';
import Plans from './components/Plans/Plans';
import NewsletterComponent from './components/NewsletterComponent/NewsletterComponent';
import Register from './components/Auth/Register';
import Login from './components/Auth/Login';
import Détail_de_la_propriété from './components/Capture_Service_Tabs/Détail_de_la_propriété';
import { Toaster } from 'react-hot-toast';
import Réserver_Solutions_de_Capture_Numérique from './pages/Réserver_Solutions_de_Capture_Numérique/Réserver_Solutions_de_Capture_Numérique';
import ReactQueryLayout from './layouts/ReactQueryLayout';
import { useQuery } from 'react-query';
import { getProfile } from './utils/api/users';
import Home from './pages/Home/Home';
import Conditions from './pages/Conditions/Conditions';
import Politiques from './pages/Politiques/Politiques';
import Commandes from './pages/Commandes/Commandes';
import Contact from './pages/Contact/Contact';

function App() {
  const { data: token } = useQuery('token', () =>
    localStorage.getItem('token')
  );

  const { data: profile } = useQuery('profile', getProfile);

  const router = !token
    ? createBrowserRouter([
        {
          path: '/register',
          element: (
            <div className=''>
              <Register />
            </div>
          ),
          errorElement: <></>,
        },
        {
          path: '/',
          element: <Home />,
        },
        {
          path: '/conditions-utilisation',
          element: <Conditions />,
        },
        {
          path: '/politiques-confidentialité',
          element: <Politiques />,
        },
        {
          path: '/mes-commandes',
          element: <Login />,
        },
        {
          path: '/detail',
          element: <Login />,
        },
        {
          path: '/contact',
          element: (
            <div className=''>
              <Home>
                <Contact />
              </Home>
            </div>
          ),
          errorElement: <></>,
        },
        {
          path: '/:id',
          element: <Home />,
          errorElement: <></>,
        },
        {
          path: '/login',
          element: (
            <div className=''>
              <Login />
            </div>
          ),
          errorElement: <></>,
        },
      ])
    : createBrowserRouter([
        {
          path: '/',
          element: <Home />,
        },
        {
          path: '/conditions-utilisation',
          element: <Conditions />,
        },
        {
          path: '/politiques-confidentialité',
          element: <Politiques />,
        },
        {
          path: '/mes-commandes',
          element: <Commandes />,
        },
        {
          path: '/login',
          element: <Commandes />,
        },

        {
          path: '/detail',
          element: (
            <div className=''>
              <Home>
                <Réserver_Solutions_de_Capture_Numérique />
              </Home>
            </div>
          ),
          errorElement: <></>,
        },
        {
          path: '/contact',
          element: (
            <div className=''>
              <Home>
                <Contact />
              </Home>
            </div>
          ),
          errorElement: <></>,
        },

        {
          path: '/:id',
          element: <Home />,
          errorElement: <></>,
        },
      ]);

  return (
    <div className='App'>
      <RouterProvider router={router}></RouterProvider>
      <Toaster position='top-right' />
      <div className='container'></div>
    </div>
  );
}

export default App;
