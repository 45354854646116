import React from 'react';
import './Commandes.css';
import Icon from '../../components/Icon/Icon';
import { useQuery } from 'react-query';
import { getAllCaptures } from '../../utils/api/capture';
import moment from 'moment';
import ProfileHeader from '../../components/ProfileHeader/ProfileHeader';

const Commandes = () => {
  const { data } = useQuery('commandes', getAllCaptures);
  const { data: profile } = useQuery('profile', getAllCaptures);

  return (
    <div className='Commandes container'>
      <ProfileHeader />
      <h1>Mes commandes</h1>
      <div className='table'>
        <div className='title'>Tous</div>
        <table>
          <thead>
            <tr>
              <th>Nom du Restaurant</th>
              <th>Demandé par:</th>
              <th>Date de création</th>
              <th>Plan schématique</th>
              <th>Google Street</th>
              <th>Superficie approcimative</th>
              {/* <th style={{ textAlign: 'center' }}>Télécharger</th> */}
            </tr>
          </thead>
          <tbody>
            {data?.map((item, i) => (
              <tr key={i}>
                <td>{item?.nom_capturer}</td>
                <td>{item?.name}</td>
                <td>{moment(item?.date).format('DD/MM/YYYY')}</td>
                <td>{item?.schématique ? 'OUI' : 'NON'}</td>
                <td>{item?.street_view ? 'OUI' : 'NON'}</td>
                <td style={{ textAlign: 'center' }}>{item?.superficie}m²</td>
                {/* <td>
                  <a href='/'>
                    <Icon name='join' />
                  </a>
                </td> */}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Commandes;
