import React, { useEffect } from 'react';
import Tab from '../../components/Tab/Tab';
import './Réserver_Solutions_de_Capture_Numérique.css';
import Détail_de_la_propriété from '../../components/Capture_Service_Tabs/Détail_de_la_propriété';
import Planifier from '../../components/Capture_Service_Tabs/Planifier';
import Contact from '../../components/Capture_Service_Tabs/Contact';
import Vérifier from '../../components/Capture_Service_Tabs/Vérifier';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { CaptureSchema } from '../../utils/zod_check';
import { Link, useSearchParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import Steps from '../../components/Steps/Steps';
import Module from '../../components/Capture_Service_Tabs/Module/Module';
import Checkout from '../../components/Capture_Service_Tabs/Checkout/Checkout';

function Réserver_Solutions_de_Capture_Numérique() {
  const [params, setParams] = useSearchParams();
  const tab = Number(params.get('tab') || 0);
  const { data: profile } = useQuery('profile');
  const {
    register,
    watch,
    formState: { errors },
    handleSubmit,
    setValue,
    getValues,
  } = useForm({
    resolver: zodResolver(CaptureSchema),
    defaultValues: { unite_mesure: 'm2' },
  });

  const Onsubmit = handleSubmit((data) => {
    params.set('tab', tab + 1);
    setParams(params);
  });

  const Go_To = (t) => {
    params.set('tab', t);
    setParams(params);
  };

  if (tab !== 0 && !watch('pays')) {
    Go_To(0);
  } else if (tab > 1 && !watch('date')) {
    Go_To(1);
  }
  useEffect(() => {
    setValue('tab', tab);
  }, [tab]);

  console.log(watch('tab'));

  return (
    <div className='CaptureServicePopup'>
      <Link to={'/'} className='overlay'></Link>
      <div>
        <Steps />
        <div className='Réserver_Solutions container'>
          <Tab
            property={''}
            tabs={['Restaurant', 'Planifier', 'Contact', 'Vérifier']}
            components={[
              // <Checkout {...{ register, errors, watch, Onsubmit, setValue }}/>,
              // <Module {...{ register, errors, watch, Onsubmit, setValue }}/>,

              <Détail_de_la_propriété
                {...{ register, errors, watch, Onsubmit, setValue }}
              />,
              <Planifier
                {...{ register, errors, watch, Onsubmit, setValue }}
              />,
              <Contact {...{ register, errors, watch, Onsubmit, setValue }} />,
              <Vérifier {...{ Go_To, getValues, profile, watch, Onsubmit }} />,
              <Module {...{ register, errors, watch, Onsubmit, setValue }} />,
              <Checkout
                {...{ getValues, register, errors, watch, Onsubmit, setValue }}
              />,
            ]}
          />
        </div>
      </div>
    </div>
  );
}

export default Réserver_Solutions_de_Capture_Numérique;
