import React from 'react';
import Icon from '../Icon/Icon';
import { useQuery } from 'react-query';
import Input from '../Inputs/Input';

function Contact({ register, errors, watch, Onsubmit, setValue }) {
  const { data: profile } = useQuery('profile');

  return (
    <div className='item-container'>
      <div className='container'>
        <h4>Qui doit être contacté sur le site ?</h4>
        <p>
          Notre technicien de capture appellera cette personne à son arrivée.
        </p>

        <form
          id='form-contact'
          action=''
          onSubmit={Onsubmit}
          style={{ marginTop: 20 }}
        >
          <div className='input-container '>
            <div className='check-box check-input'>
              <input type='radio' checked {...register('forMe')} value='moi' />
              <div className='out'>
                <Icon name='checkbox' />
              </div>
              Moi
            </div>
          </div>
          <div className='input-container '>
            <div className='check-box check-input'>
              <input type='radio' {...register('forMe')} value='autre' />
              <div className='out'>
                <Icon name='checkbox' />
              </div>
              Quelqu’un d’autre
            </div>
          </div>

          <Input
            {...{ name: 'name', lable: 'Nom complet', register, errors }}
          />
          <Input
            {...{
              name: 'email',
              type: 'email',
              lable: 'Email',
              register,
              errors,
            }}
          />
          <Input {...{ name: 'phone', lable: 'Téléphone', register, errors }} />

          <div className='input-container'></div>
          <h5 style={{ fontSize: 16 }}>
            Serez vous sur place pour accuellir le technicien ?
          </h5>
          <div className='input-container '>
            <div className='check-box check-input'>
              <input
                type='radio'
                {...register('accuellir_le_technicien')}
                value={'oui'}
                checked
              />
              <div className='out'>
                <Icon name='checkbox' />
              </div>
              Oui
            </div>
          </div>
          <div className='input-container '>
            <div className='check-box check-input'>
              <input
                type='radio'
                {...register('accuellir_le_technicien')}
                value={'non'}
              />
              <div className='out'>
                <Icon name='checkbox' />
              </div>
              Non
            </div>
          </div>
          <p style={{ fontSize: 14 }}>
            Si un technicien doit vous contacter à son arrivée, et si vous avez
            des instructions spécifiques à communiquer, veuillez les ajouter
            ci-dessous. Instructions spécifiques (stationnement, code d'accès,
            etc.)
          </p>
          <div className='input-container full'>
            <input type='text' placeholder='Instruction d’accès' />
          </div>
        </form>
      </div>

      <div className='right'>
        <h4 className='bold'>Prix estimé</h4>
        <p className='bold'>Adresse</p>
        <p>
          {watch('address')} {watch('ville')} {watch('pays')}
        </p>
        <br />
        <p className='bold'>Détails de la numérisation</p>
        <p>
          type: {watch('numerisation')}. <br />
          {watch('nom_capturer')}
        </p>
        <br />
        <p className='bold'>Détails de la propriété</p>
        <p>Un espace totalisant {watch('superficie')} m2</p>
        <div className='flex bold' style={{ justifyContent: 'space-between' }}>
          Capture services <span>{Number(watch('superficie')) * 25} MAD</span>
        </div>
        <button
          type='submit'
          form='form-contact'
          style={{ marginTop: 20 }}
          className='add '
        >
          Suivant
        </button>
      </div>
    </div>
  );
}

export default Contact;
